import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import { BaseViewLayout } from '../../layouts/BaseViewLayout'
import { eventList } from '../../resources/events'
import { NotFoundView } from '../notFound'

export const EventDetailView = () => {
  const eventId = useParams()
  const eventIndex = eventList.findIndex((event) => event.eventId === Number(eventId.eventId))
  const eventData = eventList[eventIndex]

  return eventData ? (
    <BaseViewLayout>
      <Container maxWidth='lg' className='text-center'>
        <div className=''>
          <div className=''>{eventData.title}</div>
          <div>
            {eventData.imgUrl.map((img) => (
              <img src={img} alt='' />
            ))}
          </div>
          
        </div>
      </Container>
    </BaseViewLayout>
  ) : (
    <NotFoundView />
  )
}
