import { Container } from '@mui/material'
import { BaseViewLayout } from '../../layouts/BaseViewLayout'
import { ContactForm } from '../../templates/contactForm'

export const ContactView = () => {
  return (
    <BaseViewLayout>
      <div className='flex w-full flex-col gap-6 px-8 py-16'>
        <Container maxWidth='sm'>
          <div className='py-8 text-center font-mono text-[48px]'>CONTACT</div>
          <ContactForm />
        </Container>
      </div>
    </BaseViewLayout>
  )
}
